import { Dispatch, SetStateAction } from 'react'

type StateMap = Record<string | number, any>

const recurse = (state: StateMap = {}, keys: (string | number)[], value: any): StateMap => ({
    ...state,
    [keys[0]]: keys.length === 1 ? value : recurse(state[keys[0]], keys.slice(1), value),
})

export const createMergeSetter =
    <ValueType>(setter: Dispatch<SetStateAction<any>>) =>
    (keys: (string | number)[], value: ValueType) =>
        setter((state: StateMap) => recurse(state, keys, value))

export const objectKeys = <T extends {}>(obj: T) => Object.keys(obj) as (keyof T)[]
