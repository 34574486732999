import { LoadingDots } from '@advanza/ui'
import { useState } from 'react'
import { call } from '@advanza/api'
import { Icon } from '@advanza/advanza_generic'

interface Props {
    providerId: number
}

const AggregatedReviewsRefreshButton = ({ providerId }: Props) => {
    const [isRefreshing, setRefreshing] = useState(false)
    const [isRefreshed, setRefreshed] = useState(false)

    const onRefresh = () => {
        if (!isRefreshing) {
            setRefreshing(true)
            call(`office/aggregated-reviews/refresh-aggregated-reviews/${providerId}`).finally(
                () => {
                    setRefreshing(false)
                    setRefreshed(true)
                }
            )
        }
    }

    return (
        <div>
            <div>
                <button
                    className="plain"
                    onClick={onRefresh}
                    disabled={isRefreshing || isRefreshed}>
                    {isRefreshing ? (
                        <LoadingDots />
                    ) : (
                        <span>
                            <Icon>refresh</Icon> attempt refresh aggregated reviews
                        </span>
                    )}
                </button>
            </div>
            {isRefreshed && (
                <div>
                    <span>refreshing... it may take a few minutes or more if it is busy</span>
                </div>
            )}
        </div>
    )
}

export default AggregatedReviewsRefreshButton
