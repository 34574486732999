import { call } from '@advanza/api'
import { changeFavicon } from 'misc/changeFavicon'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import Root from './containers/Root'
import '@advanza/css/reset.module.css'
import './global.css'

changeFavicon()

const root = createRoot(document.getElementById('root'))

const renderApp = () =>
    root.render(
        <HelmetProvider>
            <BrowserRouter>
                <Root />
            </BrowserRouter>
        </HelmetProvider>
    )

if (!(window._user && window._user.id)) {
    call('apps/get-app-vars/office').then((json) => {
        window._user = json.user
        window._appConfig = json.config
        renderApp()
    })
} else {
    renderApp()
}
