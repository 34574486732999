import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import { Divider, Icon } from '@advanza/ui'
import { invalidateNotes } from 'actions/notes'
import { fetchProvider } from 'actions/providers'
import AdminUser from 'components/adminUsers/AdminUser'
import FreeLeadsButton from 'components/providers/FreeLeadsButton'
import FreeTrialButton, { FREE_TRIAL_LEAD_TYPE } from 'components/providers/FreeTrialButton'
import FreeTrialCancelButton from 'components/providers/FreeTrialCancelButton'
import FormattedTime from 'components/ui/FormattedTime'
import SafeButton from 'components/ui/SafeButton'
import { useAppDispatch } from 'hooks/hooks'
import { useProvider } from 'hooks/providerHooks'
import { Provider } from 'interfaces/Providers'
import style from './freeLeads.module.scss'

interface Props {
    providerId: number
}

const FreeLeads = ({ providerId }: Props) => {
    const dispatch = useAppDispatch()

    const onRevoke = (id: number) => {
        return call('office/providers/revoke-free-leads/' + id, { method: 'post' }).then(
            () => {
                dispatch(fetchProvider(providerId, true))
                dispatch(invalidateNotes())
            },
            () => {
                return Promise.reject()
            }
        )
    }

    const provider: Provider | undefined = useProvider(providerId).provider

    if (!provider) {
        return null
    }

    return (
        <>
            {provider.free_leads.map(
                (
                    {
                        nr_leads,
                        nr_leads_left,
                        active,
                        free_lead_id,
                        user_id,
                        comment,
                        created,
                        expiration,
                        enable_after_nr_leads_payed,
                        free_lead_type,
                    },
                    i
                ) => {
                    const isFreeTrial = free_lead_type === FREE_TRIAL_LEAD_TYPE

                    return (
                        <Row key={i} className={!active ? style.inActive : ''} between="xs">
                            <Col xs>
                                <b>
                                    {nr_leads_left}/{nr_leads}
                                </b>{' '}
                                leads left from <AdminUser userId={user_id} />{' '}
                                <small>
                                    {/* @ts-ignore */}
                                    <FormattedTime format="dd-MM" date={created} />{' '}
                                    {!provider.isSignedOrFreeTrial && expiration && (
                                        <>
                                            expiry{' '}
                                            <FormattedTime
                                                /* @ts-ignore */
                                                format="dd-MM"
                                                date={expiration}
                                            />{' '}
                                        </>
                                    )}
                                    {comment}{' '}
                                </small>
                                {enable_after_nr_leads_payed && (
                                    <>
                                        {' '}
                                        <small>
                                            [require {enable_after_nr_leads_payed} payed leads]
                                        </small>
                                    </>
                                )}
                                {isFreeTrial && (
                                    <b style={{ color: '#0ea66b' }}>
                                        {' '}
                                        <Icon
                                            name="explosion"
                                            green
                                            fontSize={16}
                                            style={{
                                                verticalAlign: 'middle',
                                                paddingLeft: 3,
                                            }}
                                        />{' '}
                                        Free trial
                                    </b>
                                )}
                            </Col>
                            <Col x>
                                <SafeButton
                                    buttonText={<Icon name="undo" />}
                                    action={() => onRevoke(free_lead_id)}
                                    // @ts-ignore
                                    confirmMessage={
                                        isFreeTrial ? 'delete free trial' : 'delete free leads'
                                    }
                                    cancelText={false}
                                />
                            </Col>
                        </Row>
                    )
                }
            )}
            <Divider m />
            <FreeLeadsButton providerId={providerId} />
            {!provider.isSigned &&
                !provider.isCancelSigningDate &&
                !provider.isUnsubscribed &&
                provider.subscriptionStatus !== 'unverified' &&
                !provider.isFreeTrial && (
                    <>
                        <Divider m />
                        <FreeTrialButton providerId={providerId} />
                    </>
                )}
            {provider.active_free_trial && (
                <>
                    <Divider m />
                    <FreeTrialCancelButton
                        onCancel={() =>
                            provider.active_free_trial &&
                            onRevoke(provider.active_free_trial.free_lead_id)
                        }
                    />
                </>
            )}
        </>
    )
}

export default FreeLeads
