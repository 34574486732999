import { Col, Row } from '@advanza/grid'
import { SelectCheckbox } from '@advanza/input'
import { Button, Divider, HoverText, Icon } from '@advanza/ui'
import { fetchRegions } from 'actions/regions'
import { saveService } from 'actions/services'
import LinearLoader from 'components/LinearLoader'
import AutoRow from 'components/misc/AutoRow'
import CalendarPicker from 'components/services/CalendarPicker'
import CategoryPicker from 'components/services/CategoryPicker'
import EntityComponent from 'components/services/EntityComponent'
import OptionPicker from 'components/services/OptionPicker'
import ReleaseDatePicker from 'components/services/ReleaseDatePicker'
import ServicesSelect from 'components/services/ServicesSelect'
import SyncSubServicesButton from 'components/services/SyncSubServicesButton'
import EntityComponentContainer from 'containers/EntityComponentContainer'
import style from './Service.module.css'

class Service extends EntityComponent {
    editFields = {
        name: {
            type: 'text',
            msg: 'Name',
        },
        name_providers: {
            type: 'text',
            msg: 'Name providers plural e.g. "webdesigners"',
        },
        name_provider_article: {
            type: 'text',
            msg: 'Article (i.e. de/het)',
        },
        name_provider: {
            type: 'text',
            msg: 'Name providers singular e.g. "webdesigner"',
        },
        impact_phrase: {
            type: 'text',
            msg: 'Impact phrase E.g.:"Select the service you need."',
        },
        url: {
            type: 'text',
            msg: 'Url',
            validator: (val) => /^[ -~]+$/.test(val),
            errorMsg: 'Must not contain non ascci characters',
        },
        visible: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Visible',
        },
        subscribable: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Subscribable',
        },
        service_id_nl: {
            placeholder: 'service_id NL',
        },
        no_details_allowed: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'No details allowed',
        },
        auto_select_premium_only: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Auto select premium only',
        },
        use_active_campaign: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'use active campaign',
        },
        alt_conversion: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Check Availability button',
        },
        allow_website_button: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Allow website button',
        },
        has_price_page: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Has price page',
        },
        enable_calendar: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Enable calendar',
        },
        enable_moment_picker: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Enable moment picker',
        },
        enable_month_picker: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Enable month picker',
        },
        enable_candidate_matches: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Enable candidate matches',
        },
        enable_phone_requests: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Enable phone requests',
        },
        enable_auto_quotes: {
            type: 'boolean',
            msg: 'Enable auto-quotes',
        },
        enable_profile_projects: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Enable profile projects',
        },
        enable_nurturing_emails: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Enable nurturing emails',
        },
        is_non_local_service: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Non local',
        },
        has_calculator: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Has calculator',
        },
        enable_opening_hours: {
            type: 'skeuomorphicBoolean',
            white: true,
            msg: 'Enable opening hours',
            validator: (val, entity) => (!val && !entity.enable_moment_picker) || val,
            defaultErrorMsg: 'Opening hours are required for the moment picker',
        },
        price_min: {
            msg: 'price min',
        },
        price_max: {
            msg: 'price max',
        },
        price_avg_min: {
            msg: 'price avg min',
        },
        price_avg_max: {
            msg: 'price avg max',
        },
        nr_pros: {
            placeholder: 'Nr pros',
            type: 'number',
        },
        price_unit: {
            type: 'select',
            options: [
                { title: 'per hour', value: 'hour' },
                { title: 'per day', value: 'day' },
                { title: 'per month', value: 'month' },
                { title: 'per person', value: 'person' },
                { title: 'estimate', value: 'estimate' },
                { title: 'per m2', value: 'surface' },
                { title: 'per meter', value: 'meter' },
            ],
            msg: 'Market price type',
        },
        auto_reclaim_nr_max: {
            type: 'number',
            placeholder: '5',
            hidePlaceholder: true,
        },
        auto_reclaim_percentage_max: {
            type: 'number',
            placeholder: '67',
            hidePlaceholder: true,
        },
        auto_reclaim_percentage_low_max: {
            type: 'number',
            placeholder: '20',
            hidePlaceholder: true,
        },
        seo_title: {
            placeholder: 'SEO title',
        },
        wiki_url: {
            placeholder: 'Wiki url',
        },
        seo_description: {
            placeholder:
                'SEO description vars: {{city}} {{score}} {{score}} {{rate}} (total nr ratings) and {{nrPros}} (if available)',
            textArea: true,
        },
        content_p: {
            placeholder:
                'Landing page paragraph vars: {{score}} {{amount}} {{city}} {{localProsText}}',
            textArea: true,
        },
        content_p_top: {
            placeholder:
                'Landing page paragraph TOP, if empty the SEO description is used. vars: {{city}} {{score}} {{score}} {{rate}} (total nr ratings) and {{nrPros}} (if available',
            textArea: true,
        },
        free_leads_limit: {
            msg: 'Free leads limit',
        },
        premium_promo_leads: {
            placeholder: 'Premium promo leads',
        },
        details_question_is_optional: {
            type: 'skeuomorphicBoolean',
            msg: 'details are optional',
        },
        details_question_after_conversion: {
            type: 'skeuomorphicBoolean',
            msg: 'details after conversion',
        },
        enable_subcategory_behaviour: {
            type: 'boolean',
            msg: 'Enable subcategory behaviour for SEO',
        },
        details_question: {
            placeholder: 'details_question',
        },
        details_question_ph: {
            placeholder: 'details_question_ph',
        },
        details_question_sub: {
            placeholder: 'details_question_sub',
        },
        faq_intro_text: {
            placeholder: 'FAQ intro text. vars: {{city}}',
        },
        enable_profile_prices: {
            type: 'boolean',
            msg: 'Enable profile prices',
        },
    }

    componentDidMount() {
        this.props.fetchRegions()
    }

    render() {
        const { service, entity, regions = {} } = this.props
        const isSaving = service._saving
        const filteredRegions = Object.values(regions).filter(
            ({ country_code, is_hidden }) =>
                country_code.toUpperCase() === service.country_code.toUpperCase() && is_hidden
        )

        return (
            <div className={isSaving ? 'busy' : ''}>
                {isSaving && <LinearLoader />}
                <Row between="xs" bottom="xs" className={style.buttonRow}>
                    <Col x>
                        <Button disabled={!service._isTouched} onClick={this.save}>
                            <Icon name={isSaving ? 'hourglass_empty' : 'save'} /> Save
                        </Button>
                    </Col>
                    {!entity.visible && (
                        <Col x>
                            <ReleaseDatePicker entity={entity} />
                        </Col>
                    )}
                </Row>
                <Divider />
                <div className={style.toggles}>
                    {this.renderInput('visible')}
                    {this.renderInput('subscribable')}
                    {this.renderInput('use_active_campaign')}
                    {this.renderInput('has_price_page')}
                    {this.renderInput('alt_conversion')}
                    {this.renderInput('enable_phone_requests')}
                    {this.renderInput('enable_profile_projects')}
                    {this.renderInput('auto_select_premium_only')}
                    {this.renderInput('is_non_local_service')}
                    {this.renderInput('has_calculator')}
                    {this.renderInput('enable_opening_hours')}
                    {this.renderInput('enable_nurturing_emails')}
                    {this.renderInput('enable_candidate_matches')}
                    {this.renderInput('enable_auto_quotes')}
                    {this.renderInput('enable_subcategory_behaviour')}
                    {this.renderInput('enable_profile_prices')}
                </div>
                <Divider m />
                <CalendarPicker entity={entity} onChangeEntity={this.onChangeEntity} />
                <Divider m />
                <Row>
                    <Col xs={8}>{this.renderInput('name')}</Col>
                </Row>
                <Divider m />
                <Row>
                    <Col xs={3}>{this.renderInput('name_provider_article')}</Col>
                    <Col xs={9}>{this.renderInput('name_provider')}</Col>
                </Row>
                <Divider m />
                {this.renderInput('name_providers')}
                <Divider m />
                {this.renderInput('impact_phrase')}
                <Divider m />
                {this.renderInput('url')}
                <Divider m />
                <CategoryPicker
                    onChange={(id) => this.onChangeEntity({ category_id: id })}
                    name="category_id"
                    value={entity.category_id}
                />
                <Divider m />
                {service.country_code.toUpperCase() !== 'NL' && (
                    <>
                        <ServicesSelect
                            value={entity.service_id_nl}
                            multiple={false}
                            placeholder="NL service (hreflang)"
                            countryCode={'NL'}
                            onChangeValue={(serviceIds) =>
                                this.onChangeEntity({ service_id_nl: serviceIds[0] })
                            }
                        />
                        <Divider />
                    </>
                )}
                <AutoRow noWrap>
                    <OptionPicker
                        hideService={service.service_id}
                        value={entity.main_service_option_id}
                        onChange={(optionId) =>
                            this.onChangeEntity({ main_service_option_id: optionId })
                        }
                    />
                    <SyncSubServicesButton service={service} />
                </AutoRow>
                <Divider />
                {this.renderInput('price_unit')}
                <Divider m />
                {this.renderInput('nr_pros')}
                <Divider m />
                {this.renderInput('seo_title')}
                <Divider m />
                {this.renderInput('wiki_url')}
                <Divider m />
                {this.renderInput('seo_description')}
                <Divider m />
                <RowWithHoverText hoverText='You can use limited html markup: <h2>header</h2> or <a href="/makelaar">Makelaar</a>'>
                    {this.renderInput('content_p')}
                </RowWithHoverText>
                <Divider m />
                <RowWithHoverText hoverText='You can use limited html markup: <h2>header</h2> or <a href="/makelaar">Makelaar</a>'>
                    {this.renderInput('content_p_top')}
                </RowWithHoverText>
                <Divider m />
                <RowWithHoverText hoverText='You can use limited html markup: <h2>header</h2> or <a href="/makelaar">Makelaar</a>'>
                    {this.renderInput('faq_intro_text')}
                </RowWithHoverText>
                <Divider m />
                <Row>
                    <Col xs>{this.renderInput('price_min')}</Col>
                    <Col xs>{this.renderInput('price_avg_min')}</Col>
                    <Col xs>{this.renderInput('price_avg_max')}</Col>
                    <Col xs>{this.renderInput('price_max')}</Col>
                </Row>
                <Divider m />
                <Row>
                    <Col xs>{this.renderInput('free_leads_limit')}</Col>
                    <Col xs>{this.renderInput('premium_promo_leads')}</Col>
                </Row>
                <Divider l />
                <Row>
                    <Col xs>
                        <div>
                            <b>{'Auto reclaim #'}</b>
                        </div>
                        {this.renderInput('auto_reclaim_nr_max')}
                    </Col>
                    <Col xs>
                        <div>
                            <b>
                                {`Auto reclaim high %`} <small>(easy approval)</small>
                            </b>
                        </div>
                        {this.renderInput('auto_reclaim_percentage_max')}
                    </Col>
                    <Col xs>
                        <div>
                            <b>
                                {'Auto reclaim low %'} <small>(strict approval)</small>
                            </b>
                        </div>
                        {this.renderInput('auto_reclaim_percentage_low_max')}
                    </Col>
                </Row>
                <Divider l />
                <b>Custom details question</b>
                <Divider m />
                <div className={style.details}>
                    {this.renderInput('details_question_is_optional')}
                    {this.renderInput('details_question_after_conversion')}
                </div>
                <Divider m />
                {this.renderInput('details_question')}
                <Divider m />
                {this.renderInput('details_question_sub')}
                <Divider m />
                {this.renderInput('details_question_ph')}
                <Divider m />
                {filteredRegions.length > 0 &&
                    service.country_code.toUpperCase() !== 'BE' &&
                    service.services_visible_regions && (
                        <>
                            <b>Visible regions</b>
                            <Divider m />
                            <SelectCheckbox
                                wide
                                stickyHeaderAndSearch
                                options={filteredRegions
                                    .map(({ region_id, name }) => ({
                                        value: region_id,
                                        name,
                                    }))
                                    .sort((a, b) => {
                                        const nameA = a.name.toUpperCase()
                                        const nameB = b.name.toUpperCase()
                                        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
                                    })}
                                value={service.services_visible_regions.map(
                                    ({ region_id }) => region_id
                                )}
                                onChangeValue={(regionIds) =>
                                    this.onChangeEntity({
                                        services_visible_regions: regionIds.map((regionId) => ({
                                            service_visible_region_id:
                                                service.services_visible_regions.find(
                                                    ({ region_id }) => region_id === regionId
                                                )?.service_visible_region_id || null,
                                            service_id: service.service_id,
                                            region_id: regionId,
                                            is_visible: true,
                                        })),
                                    })
                                }
                            />
                            <Divider m />
                        </>
                    )}
            </div>
        )
    }
}

export default EntityComponentContainer(Service, {
    name: 'services',
    store: 'services',
    saveFunc: saveService,
    mapStateToProps: (state) => ({
        regions: state.regions.entities.regions || {},
    }),
    mapDispatchToProps: (dispatch) => ({
        fetchRegions: () => dispatch(fetchRegions()),
    }),
})

const RowWithHoverText = ({ hoverText = '', children }) => {
    return (
        <AutoRow noWrap>
            <span style={{ width: '100%' }}>{children}</span>
            <HoverText>{hoverText}</HoverText>
        </AutoRow>
    )
}
